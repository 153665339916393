.hub_item_inner {
  position: relative;
  border-radius: 7px;
  box-sizing: border-box;
  overflow: hidden;
  border: 3px solid transparent;
  padding-top: 56.25%;
}

.hub_item_inner::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  transition: background-color .25s .1s;
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6979166666666667) 31%, rgba(0,0,0,0) 100%);
}

.activeGrid .hub_item.active>.hub_item_inner::before {
  background-color: rgba(0, 0, 0, 0);
}

.activeGrid .hub_item.active>.hub_item_inner {
  border: 3px solid #fff;
}

.hub_item_inner img {
  width: 100%;
  position: absolute;
  top: 0;
}

.hubItemTitle{
  position: absolute;
  z-index: 1;
  bottom: 0;
  font-size: 1.8em;
  width: 100%;
  padding: .6em;
  box-sizing: border-box;
}

.hubItemTitle p {
  margin: 0;
  font-weight: 800;
  color: #fff;
  text-shadow: 0 0 5px #000;
  -webkit-line-clamp: 2;
}

@media (max-width: 1280px) and (min-width:1280px) {
.hubItemTitle{
  font-size: 1.4em;
}
}