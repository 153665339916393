#jsonUrl{
	display: none;
}

#dialogDesc {
	height: 0;
	opacity: 0;
}

.vizio *,
.vidaa *,
.zeasn * {
	transition: none !important;
	-webkit-transition: none !important;
	will-change: unset !important;
	/* animation: none !important; */
}

*:focus {
	outline: none;
}

#root,
.main {
	height: 100%;
	overflow: hidden;
	color: #fff;
}

body {
	margin: 0;
	width: 100vw;
	height: 100vh;
	background-color: #141414;
	overflow: hidden;
	font-family: 'Roboto', 'system-ui' !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#voiceReader {
	position: fixed;
	left: -10000px;
	top: 0;
}

#stream_loader_container{
  pointer-events: none;
}

html[dir="rtl"] body {
	font-family: 'Arimo' !important;
}

ul{
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}

.pageHidden,
.none {
	display: none;
}

.pageWarpper {
	position: fixed;
	top: 0;
	z-index: 10;
	background-color: #141414;
}

.outline1 .page-container .page-header span {
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.outline1 .build-version {
	color: #000000;
}

.outline1 .cat-title {
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
/* global */

.colorBlue {
	color: #47b7e4;
}

.list {
	display: flex;
}

.bold {
	font-weight: bold;
}

.captialize {
	text-transform: capitalize;
}

.outline {
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.fullScreen {
	width: 100vw;
	height: 100vh;
}

.centerlize {
	display: flex;
	justify-content: center;
	align-items: center;
}

.oneLine {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cutLines {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.build-version {
	position: absolute;
	width: 100%;
	color: rgba(255, 255, 255, .85);
	font-size: 16px;
	bottom: 17px;
	text-align: center;
	text-shadow: -1px -1px 3px #000, 1px -1px 3px #000, -1px 1px 3px #000, 1px 1px 3px #000;
}

.appImage{
	width: 100%;
	height: 100%;
}

.appImage[data-layout="cover"]{
	object-fit: cover;
}