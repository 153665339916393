#castifyPlayer{
  width: 100%;
  height: 100%;
}

#videoLoader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 221;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

#adTimer {
	color: #fff;
	font-size: 20px;
	position: fixed;
	bottom: 50px;
	right: 50px;
}

html[dir=rtl] #adTimer {
	left: 50px;
	right: unset;
}

#videoLoader.seek{
  background-color: transparent;
}

#videoLoader.hidden{
  display: none !important;
}

/* default */
#videoLoader.hidden .spinner > div{
  animation: none;
}