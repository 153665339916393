#fadeScreen {
	width: 100vw;
	height: 100vh;
	background-color: #000;
	position: fixed;
	z-index: 100;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	top: 0;
}

#fadeScreen.active{
	opacity: 1;
	pointer-events: all;
}

#fadeScreen.hidden{
	opacity: 0;
	pointer-events: none;
}