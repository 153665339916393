.menu-float-ui {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100px;
	flex-direction: column;
	transform: translateZ(0);
}

html[dir="rtl"] .menu-float-ui {
	left: unset;
	right: 0;
}

.menu-section {
	position: fixed;
	z-index: 20;
	top: 0;
}

.menu-fullBG {
	position: fixed;
	background: linear-gradient(90deg, rgba(0, 0, 0, 1) 6%, rgba(0, 0, 0, .735) 26%, rgba(0, 0, 0, 0.554) 55%);
	-webkit-transition: opacity .5s;
	transition: opacity .5s;
	opacity: 0;
	pointer-events: none;
}

html[dir="rtl"] .menu-fullBG {
	background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 8%, rgba(0, 0, 0, .735) 46%, rgba(0, 0, 0, 0.554) 65%);
}

.menu-section.active .menu-fullBG {
	opacity: 1;
	pointer-events: all;
}

.navigationMenu {
	position: absolute;
	height: 100vh;
	width: 250px;
	transform: translate3d(-150px, 0, 0);
	text-align: center;
	transition: transform .2s ease-out;
	-webkit-transition: transform .2s ease-out;
	background: linear-gradient(90deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.75) 70%, rgba(0, 0, 0, 0.5) 99%);
	will-change: transform;
}

.menu-section.active .navigationMenu {
	background: transparent;
}

html[dir="rtl"] .navigationMenu {
	transform: translate3d(150px, 0, 0);
	background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.75) 70%, rgba(0, 0, 0, 0.5) 99%);
}

.menu-section.active:not(.close_menu) .navigationMenu {
	transform: translate3d(0, 0, 0);
}

.border {
	position: absolute;
	height: 100%;
	width: 1px;
	right: -1px;
	top: 0;
	background-image: linear-gradient(to top, rgba(69, 69, 74, 1) 0%, #47b7e4 46%, rgba(69, 69, 74, 1) 100%);
	opacity: 0;
}

.menu-section.active .border {
	opacity: 0;
}

html[dir="rtl"] .border {
	right: unset;
	left: 0;
}

/* menu UI */
.menu_part {
	flex: 1;
}

.close_menu .menu_part {
	display: none;
}

/* menu logo */
.menu-item-logo {
	margin-top: 30px;
	/* margin: .8em .3em 0 .3em; */
	box-sizing: border-box;
	text-align: center;
}

.menu-section .logo-img {
	max-width: 70px;
	width: 70px;
	opacity: 1;
	transition: .2s opacity .25s;
}

.menu-section.active:not(.close_menu) .logo-img {
	opacity: 0;
	transition: .1s opacity ease;
}

.menu-section .logo-img-bigger {
	margin-top: 30px;
	opacity: 0;
	max-height: 140px;
	width: auto;
}

.menu-section.active:not(.close_menu) .logo-img-bigger {
	opacity: 1;
	transition: .3s opacity ease .35s;
}

/* === menu-logo === */

/* === menu bottom === */
.powered-menu {
	position: relative;
	transition: opacity .2s;
	-webkit-transition: opacity .2s;
}

.powered-menu img {
	position: absolute;
	bottom: 20px;
	left: 50%;
	height: 50px;
	transform: translateX(-50%);
}

html[dir="rtl"] .powered-menu img {
	left: 0;
	right: 50%;
	transform: translateX(50%);
}

.menu-section .fullImage {
	opacity: 0;
	left: 20px;
	transform: none;
}

html[dir="rtl"] .menu-section .fullImage {
	left: 0;
	right: 20px;
	transform: none;
}

.menu-section.active .fullImage {
	opacity: 1;
	transition: .3s opacity ease .35s;
}

.menu-section:not(.active) .singleImage {
	transition: .2s opacity .25s;
}

.menu-section.active .singleImage {
	opacity: 0;
}

/* menu items */
.menuItemsList {
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	padding:0;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.menu-item-logo {
		margin-top: 15px;
	}

	.menu-section .logo-img {
		max-width: 60px;
		width: 60px;
	}

	.menu-section .logo-img-bigger {
		max-height: 100px;
	}

	.navigationMenu {
		transform: translate3d(-170px, 0, 0);
	}

	html[dir="rtl"] .navigationMenu {
		transform: translate3d(170px, 0, 0);
	}

	.menu-float-ui {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 80px;
		flex-direction: column;
		transform: translateZ(0);
	}

	.powered-menu img {
		height: 40px;
	}
}