.ageConfirmation_popup {
  width: 40%;
  background-color: #000;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, .8);
}

.ageConfirmation_popup h2 {
  font-size: 27px;
  margin: 0;
}

.ageConfirmation_popup p {
  line-height: 1.3em;
  font-size: 23px;
}

.confirm_area {
  display: block;
  margin-top: 20px;
  font-weight: 600;
}

.ageConfirmation_popup .popup_buttons {
  margin-top: 20px;
}


@media (max-width: 1280px) and (min-width:1280px) {
  .ageConfirmation_popup{
    width: 60%;
  }
}