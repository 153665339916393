.page_items {
	display: flex;
	flex-wrap: wrap;
}

.page_items.no_content {
	justify-content: center;
	/* transform: translateY(25%); */
}
.page_items.justify_center {
	justify-content: center;
}

.page_item {
	min-width: 24%;
	max-width: 24%;
	position: relative;
	padding: 0 15px;
	box-sizing: border-box;
}

.page_item a {
	text-decoration: none;
	color: unset;
}

.page_items.large .page_item .item-qr img {
	height: unset;
	width: 100%;
	max-width: 150px;
}

.page_items.large .page_item .item-image img {
	height: 70px;
	width: auto;
}

.page_item .item-qr img {
	width: 100%;
	max-width: 140px;
	border-radius: 10px;
}

.social-border {
	width: 2px;
	height: 50%;
	position: absolute;
	top: 50%;
	right: 0;
	min-height: 200px;
	transform: translateY(-50%);
	background-color: rgba(71, 183, 228, 0.3);
}

.page_item:nth-child(4n) .social-border,
.page_item:last-child .social-border {
	display: none;
}

html[dir="rtl"] .social-border {
	right: unset;
	left: 0;
}

.so-item {
	text-align: center;
	margin-bottom: 30px;
}

.so-item .platform_name {
	font-size: 20px;
	font-weight: 500;
	margin: 5px 0 20px 0;
}

.so-item .item-image {
	position: relative;
	box-sizing: border-box;
}

.so-item .brand_image {
	width: 80px;
	height: 80px;
}

.so-item .item-link {
	font-size: 100%;
	font-weight: 500;
	margin: 10px 0;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.so-item .brand_image {
		width: 70px;
		height: 70px;
	}
}