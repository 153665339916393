
.liveText:after {
	content: "";
	width: 8px;
	height: 8px;
	display: inline-block;
	background-color: #ff0000;
	border-radius: 50%;
	margin: 0 7px;
}

.liveText {
	margin: 2px 0;
	color: #fff;
	align-items: center;
}