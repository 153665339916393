.actions_top_container{
	color: #fff;
	margin: 40px 70px 0;
}

.actions_list{
	align-items: center;
}

.video_option_btn{
	width: 70px;
	height: 70px;
	border-radius: 50%;
	margin-right: 40px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .5);
	cursor: pointer;
	transition: transform .15s .1s;
	z-index: 1;
}

html[dir="rtl"] .video_option_btn{
	margin: 0;
	margin-left: 40px;
}

.row_active .video_option_btn.active{
	background-color: rgba(69, 207, 255, 1);
}


/* ===== Action Item ===== */
.action_item{
	transition: transform .2s, opacity .15s;
	transform: translateX(-100%);
	opacity: 0;
	pointer-events: none;
	position: relative;
	cursor: pointer;
	margin-right: 30px;
	font-size: 22px;
}

.row_active .action_item{
	transform: translateX(0%) !important;
	opacity: 1;
	pointer-events: all;
}

html[dir="rtl"] .action_item{
	transform: translateX(100%);
	margin-right: 0;
	margin-left: 30px;
}

.action_item .item_title {
	position: absolute;
	margin: 0;
	left: 50%;
	white-space: nowrap;
	transform: translate(-50%, -40px);
	opacity: 0;
	text-shadow: -1px -1px rgba(0, 0, 0, .5), 1px -1px rgba(0, 0, 0, .5), -1px 1px rgba(0, 0, 0, .5), 1px 1px rgba(0, 0, 0, .5);
}

.row_active .action_item.active .item_title{
	opacity: 1;
}

.action_item_bg{
	background-color: rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 5px rgba(0, 0, 0, .5);
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.row_active .action_item.active .action_item_bg{
	background-color: rgb(69, 207, 255);
}





.options_items{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 100%);
	margin: 0;
	opacity: 0;
	pointer-events: none;
	padding-top: 10px;
	font-size: 22px;
}

.action_item.active .options_items{
	opacity: 1;
	pointer-events: all;
}

.options_items li {
	padding-left: 7px;
	border-left: 2px solid transparent;
	margin: 15px 0;
}

.options_items p{
	margin: 0;
	white-space: nowrap;
	opacity: .8;
}

.row_active .options_items .active p{
	opacity: 1;
	text-shadow: 1px 0px 0px #fff;
	/* font-weight: 700; */
}

.options_items .selected{
	border-left: 2px solid #fff;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.video_option_btn{
		width: 65px;
		height: 65px;
		margin-right: 25px;
	}	
	
	.action_item_bg{
		width: 45px;
		height: 45px;
	}
}