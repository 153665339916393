/* ========== GRID STYLE ========== */
.search_grid {
	transition: .3s opacity;
}

.grid_view_hidden {
	opacity: .45;
}

.grid_view_hidden>div {
	pointer-events: none;
}

/* ========== INPUT STYLE ========== */

.search_input_container {
	padding: 0 12%;
	margin: 80px 0 50px;
}

#searchInput {
	box-sizing: border-box;
	width: 100%;
	background-color: transparent;
	padding: .5em .8em;
	font-size: 30px;
	border-radius: 2em;
	transition: .2s transform;
}

#searchInput.active {
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
}

#searchInput:focus {
	outline: none;
}

#searchInput::placeholder {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-weight: 500;
	text-transform: capitalize;
	color: inherit;
}

/* ========== KEYBOARD STYLE ========== */
.searchFields:empty::before {
	content: "Search";
	color: inherit;
	opacity: .7;
}

.searchFields {
	border: 3px solid;
	border-radius: 36px;
	width: 70%;
	min-height: 42px;
	margin: 0 auto 50px;
	padding: 10px 20px;
	font-size: 35px;
	display: flex;
	align-items: center;
}

.resultsNum {
	font-size: 30px;
	margin: 15px 0 0;
	display: flex;
	justify-content: flex-end;
	opacity: 0;
}

.resultsNum.visible {
	opacity: 1;
}

.resultsNum p {
	margin: 0;
}

.results-container.hide::before {
	content: "";
	display: block;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	z-index: 1;
}

/* ========== GRID ITEM ========== */

.search .slider-item {
	min-width: 20%;
	max-width: 20%;
	padding: 0 12px 12px 0;
	position: relative;
}

.search .activeGrid .carousel_item.active .thumbnail-wrapper {
	box-shadow: 1px 1px 4px rgba(0, 0, 0, .35), -1px -1px 4px rgba(0, 0, 0, .35);
	border-radius: 4px;
}

.search .activeGrid .carousel_item.active {
	color: #000;
}

#no_match {
	font-weight: 500;
	font-size: 34px;
	text-align: center;
	margin: 2em 0 3em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@media (max-width: 1280px) and (min-width:1280px) {
	#searchInput {
		width: 100%;
		padding: 15px;
		height: 60px;
	}

	#no_match {
		font-size: 30px;
		margin: 2em 0 3em;
	}

	.searchFields {
		border-radius: 30px;
		min-height: 42px;
		margin: 0 auto 40px;
		padding: 7px 20px;
		font-size: 35px;
	}

	.search .slider-item {
		min-width: 25%;
		max-width: 25%;
		padding: 0 8px 8px 0;
	}

}