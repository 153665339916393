.storeItem {
	position: relative;
	min-width: 25%;
	max-width: 25%;
	color: #000;
	box-sizing: border-box;
	cursor: pointer;
  padding: 0 10px 20px;
}

.productInner{
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  border: 5px solid transparent;
}

.activeGrid .storeItem.active .productInner {
	border: 5px solid #47b7e4;
}

.store-itemImage {
	min-height: 350px;
	max-height: 350px;
	min-width: auto;
	max-width: 100%;
	margin: auto;
	position: relative;
}

.store-itemData::after{
  content: "";
  background-color: rgba(0, 0, 0, .5);
  height: 2px;
  width: 100%;
  display: block;
  margin-bottom: .5em;
}

.store-itemImage img {
	max-height: 350px;
}

.store-itemData{
	font-size: 20px;  
}

.store-title {
  font-size: inherit;
  font-weight: 600;
	margin: .5em 0 0;
	line-height: 1.1em;
	height: 2.2em;
	-webkit-line-clamp: 2;
}

.store-price {
	font-size: 1.3em;
	font-weight: 600;
  margin: .35em 0;
}

.store-linkSection{
	position: relative;
	align-items: center;
}

.store-linkSection-text {
	font-size: 20px;
	line-height: 1.3em;
  width: 70%;
}

html[dir="rtl"] .store-linkSection-text {
	text-align: right;
}

.store-linkSection-text p {
  margin: 0;
}


.store-linkSection-qrImg {
  width: 100px;
}

.store-linkSection-qrImg img {
  height: 100px;
}

/* widgets */

.sale {
	position: absolute;
	width: 100px;
	height: 100px;
	background-color: #47b7e4;
	text-align: center;
	border-radius: 50%;
	top: 10px;
	right: 10px;
	z-index: 1;
}

html[dir="rtl"] .sale {
	right: unset;
	top: 10px;
	left: 10px;
}

.retail {
	font-size: 20px;
	color: #A9A9A9;
}

.sale span {
	color: #fff;
	font-size: 26px;
	line-height: 100px;
	font-weight: 900;
}











@media (max-width: 1280px) and (min-width:1280px) {
	.storeItem {
		min-width: 33%;
		max-width: 33%;
	}

	.store-itemImage {
		min-height: 250px;
		max-height: 250px;
	}
	.store-itemImage img {
		max-height: 250px;
	}

	.store-itemData {
		font-size: 22px;
	}
	
	.store-linkSection-text {
		font-size: 18px;
		line-height: 1.5em;
	}
}