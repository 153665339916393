.live .page-inner{
	padding: 0;
}

.active_video_container {
	margin-bottom: 60px;
	margin-top: 100px;
	padding: 0 100px 0 200px;
}

.ativeVideo_data {
	padding-left: 20px;
	font-size: 35px;
}

.live_video_title {
	-webkit-line-clamp: 2;
	margin: 0;
	font-size: inherit;
	font-weight: 900;
	margin-bottom: .8em;
  width: 90%;
}

.live_video_description {
	-webkit-line-clamp: 5;
  font-size: .9em;
	margin: 0;
}


.activeVideo_thumbnail {
	position: relative;
	min-width: 45%;
}

.live_ratioImage{
	padding-top: 56.25%;
}

.activeVideo_thumbnail img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: opacity .2s ease-in-out;
}

.no_live {
	color: #fff;
	font-size: 50px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 1280px) and (min-width:1280px) {
	.active_video_container {
		margin-bottom: 50px;
		margin-top: 50px;
		padding: 0 100px 0 140px;
	}

	.ativeVideo_data {
		font-size: 28px;
	}

	.activeVideo_thumbnail {
		position: relative;
		min-width: 42%;
	}
}