.stream_title {
	font-weight: bold;
	font-size: 30px;
	text-shadow: -1px -1px #000, 1px -1px #000, -1px 1px #000, 1px 1px #000;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
}

.controls>*, .progress_bar_container {
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bottomSection {
	width: 100%;
	margin-top: auto;
	height: 30vh
}

.bottomSection .inner {
	padding: 0 70px;
	box-sizing: border-box;
}

.bottomSection.noCarusels {
	height: initial;
	padding-bottom: 30px;
}

.inner.carouselActive {
	opacity: 0;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.bottomSection {
		height: 38vh
	}

	.bottomSection .inner {
		padding: 0 50px;
		box-sizing: border-box;
	}

	.stream_title {
		width: 100%;
		font-size: 27px;
	}

	.controls-container {
		padding: 0 40px;
	}

	.progressHoverContainer {
		max-width: 90px;
		min-width: 90px;
		padding: 8px 0;
		font-size: 19px;
	}
}