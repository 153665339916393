.carousel .slider-item.carousel_item {
	min-width: 20%;
	max-width: 20%;
	padding: 0 5px;
	position: relative;
}

.thumbnail-container {
	padding: 28.125% 0;
	position: relative;
	height: 0;
	background-color: #464343;
	border-radius: 4px;
	overflow: hidden;
}

.thumbnail-img {
	position: absolute;
	top: 0;
	bottom: 0;
}

.video_info {
	font-size: 22px;
	padding: .2em .3em;
	font-weight: 500;
	max-height: 2.4em;
	min-height: 2.4em;
	line-height: 1.2em;
	border-radius: 0 0 4px 4px;
}

.video_title {
	-webkit-line-clamp: 2;
}

/* ============================ ACTIVE STYLES ============================ */

.slider-item.active .thumbnail-container {
	border-radius: 4px 4px 0 0;
}

.active_carousel .slider-item.active .video_info,
.activeGrid .carousel_item.active .video_info {
	background: #fff;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.slider-item.carousel_item {
		min-width: 25%;
		max-width: 25%;
	}

	.video_info {
		font-size: 17px;
	}
}