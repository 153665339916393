.keyboardWrapper {
  width: 880px;
  margin: 0 auto 100px;
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
  height: 80px;
  padding: 1px;
  box-sizing: border-box;
  color: #fff;
  font-size: 30px;
}

.btn>div {
  height: 100%;
  transition: background-color .2s;
  background-color: #242424;
}

.btn>div:hover, .btn.active>div {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.btn.act {
  min-width: 160px;
  max-width: 160px;
  width: 160px;
}

.btn.space {
  min-width: 480px;
  max-width: 480px;
  width: 480px;
}

.btn.space svg {
  font-size: 45px;
}

@media (max-width: 1280px) and (min-width:1280px) {
  .keyboardWrapper {
    width: 700px;
    margin: 0 auto 20px;
  }

  .btn {
    min-width: 60px;
    max-width: 60px;
    width: 60px;
    height: 60px;
    font-size: 27px;
  }

  .btn.act {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
  }

  .btn.space {
    min-width: 360px;
    max-width: 360px;
    width: 360px;
  }

}