#player {
	height: 100%;
	max-width: 100%;
	background-color: #000;
	background-repeat: no-repeat;
	background-size: cover;
}

.player_wrapper {
	height: 100%;
}

.player_wrapper.audio {
	background-repeat: no-repeat;
	background-size: cover;
}

.player_wrapper.audio::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, .5);
}

.castifyPlayer {
	height: 100%;
	width: 100%;
}

#aniplayer {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 201;
}

#videoContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 200;
	transform: translateZ(0);
}

.video_UI_color_unactive {
	background-color: #757575;
}

.waterMark {
	position: absolute;
	top: 20px;
	right: 20px;
}

html[dir="rtl"] .waterMark {
	right: unset;
	left: 20px;
}

/* ======================= */

.video_container_inner {
	position: relative;
}

.player_UI {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: #fff;
	background:
		linear-gradient(0deg, rgba(0, 0, 0, .7) 12%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 80%),
		linear-gradient(180deg, rgba(0, 0, 0, 0.7) 3%, rgba(0, 0, 0, 0) 35%);
	opacity: 0;
	pointer-events: none;
	transform: translateZ(0);
	transition: opacity .3s;
}

.player_UI.showControls {
	opacity: 1;
	pointer-events: all;
}

#adTimer {
	color: #fff;
	font-size: 20px;
	position: fixed;
	bottom: 50px;
	right: 50px;
}

html[dir=rtl] #adTimer {
	left: 50px;
	right: unset;
}

.carouselManagerHolder {
	margin-top: 20px;
}