.controls {
	display: flex;
	align-items: center;
	color: #fff;
}

.controls::before{
  content: "";
  flex: 1;
}

.controls_item {
	flex: 1;
}

.controls_item svg {
	font-size: 52px;
}

.playBtn{
	width: 80px;
	height: 80px;
	border-radius: 50%;
	cursor: pointer;
	margin: auto;
}

.row_active .controls_item.active .playBtn {
	background: rgba(69, 207, 255, 0.7);
	transform: scale(1.2);
}

@media (max-width: 1280px) and (min-width:1280px) {
	.controls_item svg {
		font-size: 45px;
	}

	.playBtn {
		width: 65px;
		height: 65px;
	}
}