.managerWrapper{
  z-index: 1;
  color: #fff;
  transition: .3s transform;
}

.managerScrollWrapper{
  transition: transform .35s linear;
  pointer-events: none;
}

.slider{
  transition: transform .25s linear;
}

.slider:empty{
  padding: 120px 0;
}

#videoContainer .activeCarouselManager {
  transform: translate3d(0, -340px, 0);
}

.brand_4fpgggl6py .detailed_background .managerWrapper {
	transform: translateY(-160px);
}

/* @media (max-width: 1280px) and (min-width:1280px) {
  .detailed_background .managerWrapper{
    transform: translateY(-80px);
  }
} */