.ads-message-container {
	display: inline-block;
	position: fixed;
	z-index: 225;
	bottom: 100px;
	left: 50px;
	background-color: rgba(0, 0, 0, 0.8);
	align-items: center;
	border-radius: 5px;
}

.ads-message-imageContainer{
	width: 200px;
}

.ads-message-imageContainer>div{
	position: relative;
	padding-top: 56.25%;
}

.ads-message-img{
	position: absolute;
	top: 0;
}

html[dir="rtl"] .ads-message-container {
	right: 50px;
	left: unset;
}

#message-ads {
	font-size: 32px;
	padding: 0 25px;
	color: #fff;
	margin: 0;
}

#adContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 220;
	pointer-events: none;
	overflow: hidden;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.ads-message-container>div {
		width: 160px;
	}

	#message-ads {
		font-size: 26px;
	}
}