.screenSaverContainer {
	position: fixed;
	background-color: #000;
	z-index: 130;
	top: 0;
}

.screen_saver_inner {
	height: 100%;
}

.screen_saver_inner::after{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image:	linear-gradient(0deg, rgba(0, 0, 0, .8) 12%, rgba(0, 0, 0, 0.4) 70%, rgba(9, 9, 9, 0) 96%);
}

#screenSaverImg {
	width: 120%;
	height: 100%;
	-webkit-transition: opacity .4s, transform 15s .2s linear;
	transition: opacity .4s, transform 15s .2s linear;
	object-fit: cover;
}

#screenSaverImg, 
.screenSaver-title,
.screenSaver-logo{
	opacity: 0;
}

.imageLoad #screenSaverImg {
	opacity: 1;
}

.powerBy {
	opacity: 0;
	position: absolute;
	top: 30px;
	right: 30px;
	width: 120px;
}

.screenSaver_bottomLine {
	display: flex;
	align-items: flex-end;
	position: absolute;
	bottom: 0;
	padding: 50px;
	z-index: 140;
	width: 100%;
	box-sizing: border-box;
}
.textContainer{
	flex: 2;
}

.screenSaver-title {
	max-width: 80%;
	font-size: 50px;
	margin: 0;
	color: #fff;
	text-shadow: -1px -1px 10px #000, 1px 1px 10px #000;
	-webkit-line-clamp: 2;
}

.screenSaver-logo {
	-webkit-transition: opacity .4s;
	transition: opacity .4s;
	margin: 0;
	max-width: 260px;
}

.imageLoad .powerBy {
	animation: waterMarkAnim .4s linear .3s 1 alternate forwards;
	-webkit-animation: waterMarkAnim .4s linear .3s 1 alternate forwards;
}

.imageLoad .screenSaver-title {
	animation: waterMarkAnim .4s ease-out .7s alternate forwards;
	-webkit-animation: waterMarkAnim .4s ease-out .7s alternate forwards;
}


.imageLoad .screenSaver-logo {
	animation: waterMarkAnim .4s linear 1.2s 1 alternate forwards;
	-webkit-animation: waterMarkAnim .4s linear 1.2s 1 alternate forwards;
}

@keyframes waterMarkAnim {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 1280px) and (min-width:1280px) {
	.screenSaver-title {
		font-size: 50px;
	}

	#screenSaver-logo {
		width: 140px
	}
}