.page-container {
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
}

.page_content {
	width: 100%;
	z-index: 1;
}

.BlueBox.witout_items {
	position: absolute;
	bottom: 60px;
}

.page-header {
	font-size: 35px;
	margin-bottom: 60px;
	padding-left: 200px;
	padding-top: 40px;
}

html[dir="rtl"] .page-header {
	padding-left: 0;
	padding-right: 200px;
	text-align: right;
}

.page-title {
	font-size: 45px;
	font-weight: bold;
	margin: 0;
}

.borderHeader {
	background: #000;
	height: 100%;
	padding: 0 1.5px;
	margin: 20px;
}

.page_header_title {
	font-weight: normal;
}

.page-description {
	font-size: 26px;
	letter-spacing: 1px;
	margin: 25px 0 90px;
	line-height: 1.2em;
	overflow: hidden;
}

.page-inner {
	padding: 0 100px 0 200px;
	box-sizing: border-box;
}

html[dir="rtl"] .page-inner {
	padding: 0 200px 0 90px;
}

.side-image {
	height: 100vh;
	min-width: 500px;
	max-width: 500px;
	opacity: 0;
	transition: opacity .2s;
}

.side-image img {
	object-fit: cover;
}

.BlueBox {
	width: 100%;
	padding: 20px;
	background-color: rgba(71, 183, 228, 0);
	margin: 50px auto 0 auto;
	text-align: center;
	line-height: 1.3em;
	font-size: 28px;
	box-sizing: border-box;
}

.full .BlueBox {
	width: 80%;
}

@media (max-width: 1280px) and (min-width:1280px) {

	/* general */
	.page-title {
		font-size: 35px;
	}

	.page_header_title {
		font-size: 30px;
	}

	.side-image {
		min-width: 350px;
		max-width: 350px;
		background-position: left !important;
	}

	.page-inner {
		padding-left: 165px;
		padding-right: 85px;
	}

	html[dir="rtl"] .page-inner {
		padding: 0 165px 0 85px;
	}

	.page-description {
		font-size: 20px;
	}

	.basic .page-description {
		height: 200px !important;
		margin: 15px 0;
	}

	.page-header p {
		margin-bottom: 15px;
	}

	.page-header {
		padding-left: 165px;
		margin-bottom: 40px;
	}

	html[dir="rtl"] .page-header {
		padding-right: 165px;
	}

	/* store */
}