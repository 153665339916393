/* thumbnail loader */

.placeholder-item {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #141414;
}

.placeholder-item::before {
	content: '';
	display: block;
	position: absolute;
	left: -150px;
	top: 0;
	height: 100%;
	width: 150px;
	background: linear-gradient(to right, transparent 0%, #1e1e1e 50%, transparent 100%);
	animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
	-webkit-animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
	from {
		left: -150px;
	}
	to {
		left: 100%;
	}
}

@-webkit-keyframes load {
	from {
		left: -150px;
	}
	to {
		left: 100%;
	}
}

/* store loader */

.store-loader-container {
	width: 50px;
	height: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.store-loader {
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #000;
	width: 50px;
	height: 50px;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
	box-sizing: border-box;
}

/* Default loader */
.defaultLoaderContainer {
  width: 120px;
  height: 120px;
  position: relative;
}

.defaultLoaderContainer > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .8;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2.7s infinite ease-in-out;
  animation: sk-bounce 2.7s infinite ease-in-out;
}

div.double-bounce2 {
  -webkit-animation-delay: -1.35s;
  animation-delay: -1.35s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
    opacity: 1;
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}