.progress_bar_container:hover .progressHoverContainer {
	display: flex;
}

div.progressHoverContainer {
	position: absolute;
	max-width: 100px;
	min-width: 100px;
	border-radius: 20px;
	padding: 10px 0;
	background-color: rgba(0, 0, 0, .6);
	bottom: 20px;
	display: none;
	font-size: 20px;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.progressHoverContainer {
		max-width: 90px;
		min-width: 90px;
		padding: 8px 0;
		font-size: 19px;
	}
}