.videoError_popup {
	background-color: #000000;
	border: 2px solid rgba(71, 183, 228, .8);
	text-align: center;
	padding: 50px 100px;
}

.videoError_popup h2{
	font-size: 37px;
	margin: 0;
	font-weight: 500;
}

.okButton{
	margin-top: 50px;
	border: 2px solid #fff;
	display: inline-block;
	padding: 15px 40px;
	cursor: pointer;
	font-size: 20px;
}

@media (max-width: 1280px) and (min-width:1280px) {
  .videoError_popup {
		padding: 30px 60px;
	}

	.videoError_popup h2{
		font-size: 32px;
	}
}