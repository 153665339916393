.slider-item.movie_carousel {
  min-width: 16.666667%;
  max-width: 16.666667%;
  padding-left: 0px;
}

.movie_inner {
  padding: 7px;
  border: 3px solid transparent;
}

.movie_carousel .ratio_keep {
  padding-top: 146%;
  position: relative;
}

.movieCarousel-img{
  position: absolute;
  top: 0;
  border-radius: 5px;
}

.movie-caro-title {
  min-height: 2.2em;
  max-height: 2.2em;
  line-height: 1.1em;
  color: #fff;
  font-size: 21px;
  -webkit-line-clamp: 2;
  padding: 6px 10px 0;
}

/* ============================ ACTIVE STYLES ============================ */

.movie_carousel.active .movie_inner {
  border: 3px solid #fff;
}

@media (max-width: 1280px) and (min-width:1280px) {

  /* movie_carousel */
  .slider-item.movie_carousel {
    display: inline-block;
    box-sizing: border-box;
    margin-right: -4px;
  }

  .movie-caro-title {
    font-size: 20px;
    padding-top: 5px;
  }
}