.hubPageContainer {
  padding: 20px 100px 0px 200px;
  display: flex;
  flex-direction: column;
}

.hubPageContainer .page-header{
  padding-left: 0;
}

.hub_menu {
  position: fixed;
  width: 100px;
  height: 100vh;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.75) 70%, rgba(0, 0, 0, 0.5) 99%);
  z-index: 31;
}

.hubSubPage {
  position: fixed;
  top: 0;
  z-index: 100;
}

.iconMenuHub {
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  /* border: 2px solid #fff; */
}

.iconMenuHub.active {
  background-color: #444444;
}

.iconMenuHub:hover {
  background-color: #444444;
}

.iconMenuHub svg {
  font-size: 40px;
}

.hub_item {
  min-width: 33.333%;
  max-width: 33.333%;
  padding: 0 4px 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.hubTop {
  text-align: right;
  flex: 1;
}

.hub_grid {
  justify-content: center;
  flex: 1;
}

@media (max-width: 1280px) and (min-width:1280px) {

  .hubPageContainer {
    padding: 20px 120px 0 200px;
  }

}