.nextVideoBtn {
	border-radius: 5px 0 0 5px;
	overflow: hidden;
}

.next_info {
	position: relative;
	padding: 5px 10px;
	box-sizing: border-box;
	cursor: pointer;
	flex: 1;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.row_active .controls_item.active .next_info {
	background: #fff;
	color: #000;
}

html[dir="rtl"] .next_info {
	text-align: right;
}

.next_overlay {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	color: #fff;
}

.next_label {
	font-size: 22px;
	margin: 0;
	font-weight: bold;
	margin-bottom: 7px;
	line-height: 1;
}

.next_img {
	position: relative;
	width: 180px;
	min-width: 180px;
	cursor: pointer;
	box-sizing: border-box;
}

.next_img img {
	top: 0;
	left: 0;
	filter: brightness(.7);
	border-radius: 5px;
}

.row_active .controls_item.active .next_img img{
	border-radius: 0 5px 5px 0;
}

html[dir="rtl"] .next_img svg {
	transform: rotate(180deg);
}

.next_title {
	margin: 0;
	font-size: 21px;
	-webkit-line-clamp: 2;
}

html[dir="rtl"] .next_title {
	direction: rtl;
}

@media (max-width: 1280px) and (min-width:1280px) {
  .next_label {
		font-size: 20px;
	}
	.next_title {
		font-size: 18px;
	}
	.next_img {
		width: 150px;
		min-width: 150px;
	}
}