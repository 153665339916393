.progress_bar_container {
	margin: 20px 0;
	padding: 5px 0;
	cursor: pointer;
	position: relative;
	transform: translateZ(0);
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.progress_bar {
	height: 7px;
}

.progressBar-lines {
	position: relative;
}

.progress_bar * {
	height: 100%;
}

.progressBar-watched {
	background-color: #fff;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.progress_bar.active_progress_bar .progressBar-watched {
	background-color: #45cfff;
}

.progressBar-watched::after {
	content: "";
	display: block;
	position: absolute;
	right: 0;
	top: 50%;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: inherit;
	transform: translate(50%, -50%);
	opacity: 0;
}

.progress_bar.active_progress_bar .progressBar-watched::after {
	opacity: 1;
}

html[dir="rtl"] .progress_point {
	right: unset;
	left: -12px;
}

.active_progress_bar .progress_point {
	opacity: 1;
}

.timeLabel {
	font-size: 26px;
	text-align: left;
}

html[dir="rtl"] .timeLabel {
	text-align: right;
}

.timeLabel .current_end_time {
	line-height: 1;
	position: absolute;
}


@media (max-width: 1280px) and (min-width:1280px) {
	.progress_bar_container {
		margin: 15px 0;
	}

	.progressBar-watched::after {
		width: 22px;
		height: 22px;
	}
}