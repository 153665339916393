.carousel_holder {
  /* position: absolute; */
  width: 100%;
  padding-bottom: 30px;
  pointer-events: none;
  opacity: 0;
  transition: .35s opacity .1s;
}

.carousel_holder.active_carousel {
  will-change: transform;
  pointer-events: all;
  opacity: 1;
  transition: .6s opacity;
}

.carousel_holder.active_carousel~.carousel_holder {
  opacity: .5;
}

.carousel_title {
  font-size: 30px;
  margin-bottom: .4em;
  margin-left: 200px;
  margin-top: 0;
  font-weight: 700;
  line-height: 1;
}

html[dir=rtl] .carousel_title{
  margin-left: 0;
  margin-right: 200px;
}

#videoContainer .carousel_title {
  margin-left: 70px;
}

html[dir=rtl] #videoContainer .carousel_title {
  margin-right: 70px;
  margin-left: 0;
}

.carousel-slider-wrapper {
  position: relative;
  padding-left: 190px;
  padding-right: 100px;
}

html[dir=rtl] .carousel-slider-wrapper{
  padding-left: 100px;
  padding-right: 190px;
}

#videoContainer .carousel-slider-wrapper {
  padding: 0 60px;
}

.slider {
  display: flex;
}

.slider-item {
  min-width: 20%;
  max-width: 20%;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 10px;
}

html[dir=rtl] .slider-item{
  padding-left: 0;
  padding-right: 10px;
}

/* ===== VIDEO UI ===== */
.video_UI_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.parent_control_container {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
}

.video_parent_control {
  margin-bottom: 5px;
  height: 50px;
  max-height: 50px;
}

.video_duration {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 10px;
  right: 10px;
  color: #fff;
  line-height: 1;
  font-size: 17px;
  padding: 5px;
}

html[dir=rtl] .video_duration {
  text-align: left;
  right: unset;
  left: 10px;
}

.lastWatch {
  height: 10px;
  background-color: #ff0000;
  position: absolute;
  bottom: 0;
}

.continueWatchingTitle{
  display: none;
}

.slider:empty + .continueWatchingTitle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 24px;
}

@media (max-width: 1280px) and (min-width:1280px) {
  .slider-item {
    min-width: 25%;
    max-width: 25%;
  }

  .carousel_title {
    font-size: 25px;
    margin-left: 140px;
  }

  html[dir=rtl] .carousel_title{
    margin-left: 0;
    margin-right: 140px;
  }

  #videoContainer .carousel_title {
    margin-left: 50px;
  }

  .carousel-slider-wrapper {
    padding-left: 130px;
    padding-right: 80px;
  }

  html[dir=rtl] .carousel-slider-wrapper{
    padding-left: 80px;
    padding-right: 130px;
  }

  #videoContainer .carousel-slider-wrapper {
    padding: 0 40px;
  }

  .video_duration {
    font-size: 15px;
  }
}