.menu-item {
	position: relative;
	align-items: center;
	justify-content: center;
	margin: 10px auto;
	width: 60%;
	padding: 10px 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	cursor: pointer;
}

.menu-item.selected {
	border-left-color: inherit;
}

.menu-item img{
	transition: .3s;
}

.menu-section:not(.active) .menu-item:hover img {
	transform: scale(1.2);
}

html[dir="rtl"] .menu-item {
	border-left: unset;
	border-right: 3px solid transparent;
}

html[dir="rtl"] .menu-item.selected {
	border-left-color: transparent;
	border-right-color: inherit;
}

.menu-item:last-child .menu_item_text {
	left: 17px
}

html[dir="rtl"] .menu-item:last-child .menu_item_text {
	right: 17px
}

.menu-item img {
	height: 26px;
}

.menu_item_text {
	position: absolute;
	left: 60px;
	transform: translate3d(calc(-110% - 80px), 0, 0);
	font-size: 24px;
	font-weight: 500;
	white-space: nowrap;
	opacity: .75;
}

html[dir="rtl"] .menu_item_text {
	left: 0;
	right: 60px;
	transform: translate3d(calc(110% + 80px), 0, 0);
	width: 160px;
}

.menu-item.active .menu_item_text {
	opacity: 1;
}

.menu-section.active .menu_item_text {
	transform: translate3d(0, 0, 0);
}

@media (max-width: 1280px) and (min-width:1280px) {

	.menu-item {
		padding: 8px 0;
		margin: 7px auto;
	}

	.menu-item img {
		height: 24px;
	}

	.menu_item_text {
		font-size: 21px;
		left: 45px;
	}

	html[dir="rtl"] .menu_item_text {
		right: 45px;
	}

}