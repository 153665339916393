.category_carousel.story .cat-container {
	text-align: center;
	min-width: 20%;
	max-width: 20%;
	padding: 0 10px;
	box-sizing: border-box;
	border-color: inherit;
}

html[dir="rtl"] .category_carousel.story .cat-container:first-child {
	padding-right: 0;
}

.category_carousel.story .cat-container:first-child {
	padding-left: 0;
}

.category_carousel.story .cat-item {
	margin: auto;
	width: 200px;
	height: 200px;
	padding: 8px;
	border: 5px solid transparent;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	mask-image: radial-gradient(white, black);
	transition: .3s transform;
}

.category_carousel.story .cat-item-img {
	border-radius: 50%;
	width: 100%;
	object-fit: contain;
}

.category_carousel.story .cat-title {
	margin: 0;
	margin-top: 10px;
	font-size: 25px;
	font-weight: 500;
	transition: .3s transform;
	-webkit-transition: .3s transform;
}

/* ===== ACTIVE STYLES ===== */

.category_carousel.story .cat-container.active .cat-item {
	transform: scale(1.2);
	-webkit-transform: scale(1.2);
}

.category_carousel.story .cat-container.active .cat-item-img,
.category_carousel.story .cat-container:hover .cat-item-img {
	transform: scale(1.3);
	-webkit-transform: scale(1.3);
}

.category_carousel.story .cat-container.active .cat-title,
.category_carousel.story .cat-container:hover .cat-title {
	transform: translateY(20px);
	opacity: 1;
}

/* idle */
.category_carousel.story .cat-container.idle .cat-title {
	opacity: 1;
}

.category_carousel.story .cat-container.selected .cat-item {
	border: 5px solid;
	border-color: inherit;
}

@media (max-width: 1280px) and (min-width:1280px) {

	.category_carousel.story .cat-item {
		border: 3px solid transparent;
		padding: 5px;
		margin: auto;
		min-width: 165px;
		max-width: 165px;
		height: 165px;
		min-height: 165px;
		max-height: 165px;
	}

	/* ===== ACTIVE STYLES ===== */
	.category_carousel.story .cat-container.active .cat-item-img,
	.category_carousel.story .cat-container:hover .cat-item-img {
		transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}

	.category_carousel.story .cat-title {
		font-size: 21px;
		margin-top: 5px;
	}

	.category_carousel.story .cat-container.selected .cat-item {
		border: 3px solid;
		border-color: inherit;
	}
}