.withpopup_container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1;
}

.article_container {
  width: 80%;
  height: 75%;
  background-color: #fff;
  position: relative;
}

.article_brand {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  height: 80px;
  width: 170px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
}

.article_brand img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.articleButton{
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  padding: .4em 1em;
  color: #fff;
  transform: translateY(-100%);
  background-color: rgb(131, 131, 230);
  border-radius: 5px 5px 0 0;
  border: none;
  cursor: pointer;
}

.article_container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.articleLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.articleLoader img {
  animation: spinLoader 2s linear infinite;
}