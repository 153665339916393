.imgBG {
	width: 100%;
	transition: opacity .8s .2s;
	position: absolute;
	top: 0;
	object-fit: cover;
}

.detailed_view_container {
	margin-bottom: 250px;
}

.detailed_background {
	display: flex;
	flex-direction: column;
}

.detailed_image_container {
	position: absolute;
	width: 70%;
	right: 0;
}

html[dir=rtl] .detailed_image_container {
	right: unset;
	left: 0;
}

.detailed_image_container>div {
	padding-top: 56.25%;
	position: relative;
}

.detailed_image_container::after {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 70%), linear-gradient(0, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 60%);
	transform: translateZ(0);
}

html[dir="rtl"] .detailed_image_container::after {
	background-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 70%), linear-gradient(0, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 60%);
}

.zeasn .imgBG {
	transition: none;
	-webkit-transition: none;
}

/* detailed view */

.app_info {
	position: relative;
	width: 45%;
	margin-left: 200px;
	padding-top: 60px;
	color: #fff;
}

.v4 .app_info {
	margin-left: 100px;
}

html[dir=rtl] .app_info {
	margin-right: 200px;
	margin-left: 100px;
}

html[dir=rtl] .v4 .app_info {
	margin-right: 100px;
	margin-left: 100px;
}

.app_logo {
	display: inline-block;
	position: relative;
	margin-bottom: 20px;
}

.app_logo img {
	width: auto;
	height: 129px;
}

.home_title {
	font-size: 50px;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.1em;
}

.home_desc {
	margin: 40px 0 0;
	font-size: 27px;
	line-height: 1.3em;
	-webkit-line-clamp: 5;
	min-height: 6.5em;
}

/* content-data */

.content-data-container {
	margin-top: 15px;
}

.content-item-data {
	color: #fff;
	padding: 2px;
	margin-right: 15px;
	font-size: 16px;
	background-color: rgba(255, 255, 255, .2);
	padding: .4em .8em;
	border-radius: 5px;
	display: flex;
	align-items: center;
}

html[dir="rtl"] .content-item-data {
	margin-right: 0px;
	margin-left: 20px;
}

.content-item-data p{
	margin: 0;
}

.content-item-data.resolution {
	font-weight: 600;
}

.content-item-data img {
	margin-right: 5px;
	width: 16px;
	height: 16px;
}

html[dir="rtl"] .content-item-data img {
	margin-right: 0px;
	margin-left: 5px;
}

/* error */

.error {
	color: #fff;
	position: absolute;
	bottom: 100px;
	width: 500px;
	right: calc(50% - 250px);
	text-align: center;
}

.error p {
	opacity: .5;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.v4 .app_info {
		margin-left: 60px;
	}

	.detailed_view_container {
		margin-bottom: 130px;
	}

	.app_logo img {
		width: 160px;
		height: 90px;
	}

	.app_info {
		padding-top: 40px;
		margin-left: 140px;
		margin-bottom: 0;
	}

	html[dir=rtl] .app_info {
		margin-right: 150px;
	}

	.home_title {
		font-size: 35px;
	}

	.home_desc {
		margin-top: 15px;
		font-size: 22px;
		-webkit-line-clamp: 4;
		min-height: 5.2em;
	}

	.content-item-data {
		font-size: 14px;
		padding: 5px 7px;
	}
}