#categories {
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	/* flex-wrap: wrap; */
}

.category_carousel {
	padding: 150px 100px 0 200px;
	margin-bottom: 170px;
}

html[dir="rtl"] .category_carousel {
	padding: 120px 200px 0 100px;
}

.category_carousel.paralaxBox {
	padding: 80px 100px 80px 200px;
}

.carouselManager_container {
	position: relative;
}

.category_carousel_inner {
	-webkit-transition: .2s transform linear;
	transition: .2s transform linear;
	justify-content: center;
	width: 100%;
	margin: 0;
	padding: 0;
}

.paralaxBox .category_carousel_inner {
	justify-content: flex-start;
}

.category_carousel_inner.align_start {
	justify-content: flex-start;
}

.carousels_box.multiCategories {
	transition: opacity .3s;
	-webkit-transition: opacity .3s;
}

/* game carousel {gaming)*/

.category_carousel.paralaxBox .cat-container {
	margin: 0;
	margin-right: 25px;
	position: relative;
	transition: transform .08s;
	-webkit-transition: transform .08s;
	cursor: pointer;
}

.category_carousel.paralaxBox .cat-container:hover, .activeCategory .category_carousel.paralaxBox .cat-container.active {
	transform: scale(1.08);
	-webkit-transform: scale(1.08);
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, 1);
	z-index: 100;
	/*TODO -check this z-index */
}

html[dir="rtl"] .category_carousel.paralaxBox .cat-container {
	margin: 0;
	margin-left: 25px;
}

.category_carousel.paralaxBox .cat-item {
	min-width: 745px;
	max-width: 745px;
	min-height: 405px;
	height: 405px;
	transition: .2s transform;
	-webkit-transition: .2s transform;
	overflow: hidden;
}

.category_carousel.paralaxBox .cat-title-container {
	position: absolute;
	height: 90px;
	width: 100%;
	padding: 20px;
	background-image: linear-gradient(to top, rgba(234, 28, 45, 0.9), rgba(234, 28, 45, 0.6));
	box-sizing: border-box;
	text-transform: uppercase;
	line-height: 50px;
	font-family: Roboto;
	font-size: 50px;
	font-weight: 500;
	color: #fff;
	transition: transform .2s;
	-webkit-transition: transform .2s;
	bottom: -1px;
}

.category_carousel.paralaxBox .cat-title {
	float: left;
	text-shadow: none !important;
}

.cat-title-icon {
	float: right;
}

.cat-title-icon img {
	vertical-align: middle;
}

.category_carousel.paralaxBox .cat-container::after {
	display: block;
	content: "";
	height: 40px;
	width: 100%;
	background-image: linear-gradient(to bottom, rgba(234, 28, 45, .3), rgba(234, 28, 45, 0));
	bottom: -40px;
	position: absolute;
	opacity: 0;
	transition: .1s;
	-webkit-transition: .1s;
}

/* ---idle--- */

.category_carousel.paralaxBox .cat-container.idle:not(.active) .cat-title-container {
	background-color: rgba(234, 28, 45, .85) !important;
}

.category_carousel.paralaxBox .cat-container:not(.active)::after {
	opacity: 1;
}

/* ---idle--- */

/* general */

.cat-item-img {
	background-size: 100% 100%;
	height: 100%;
	transition: .3s transform;
	-webkit-transition: .3s transform;
	background-repeat: no-repeat;
}

.activeCategory .category_carousel.paralaxBox .cat-container:hover .cat-item-img, .activeCategory .category_carousel.paralaxBox .cat-container.active .cat-item-img {
	transform: scale(1.05);
	-webkit-transform: scale(1.05);
}

/* Global carousel (story) */

/* ---active--- */

/* --idle--- */

.cat-container.idle .cat-item {
	transform: none
}

.category_carousel.category_carousel.paralaxBox .cat-container.idle .cat-title {
	transform: none;
	opacity: 1;
	font-weight: 900;
	color: #f8de08;
}

.cat-container.idle .cat-item-img {
	transform: none
}

@media (max-width: 1280px) and (min-width:1280px) {
	.category_carousel.paralaxBox .cat-item {
		min-width: 630px;
		max-width: 630px;
		min-height: 354px;
		height: 354px;
	}

	.category_carousel {
		padding: 70px 70px 0 150px;
		margin-bottom: 70px;
	}

	html[dir="rtl"] .category_carousel {
		padding: 70px 150px 0 70px;
	}
}