#splash_screen {
	position: fixed;
	z-index: 110;
	background-color: #000;
}

#splash_screen img {
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: .2s opacity;
	-webkit-transition: .2s opacity;
}

@media screen and (max-width: 992px) {
	#splash_screen img{
		object-fit: contain;
	}
}