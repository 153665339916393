.popup_container{
	background-color: rgba(0, 0, 0, 0.85);
	position: fixed;
	top: 0;
	color: #fff;
	z-index: 300;
}

.popup_buttons {
  display: flex;
  justify-content: center;
  text-align: center;
}

.popupAction-btn{
	background: none;
  color: #fff;
  width: 120px;
  padding: 13px 0;
  margin: 0 20px;
  font-size: 24px;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
}

.popupAction-btn.active {
  border: 2px solid #fff;
}

@media (max-width: 1280px) and (min-width:1280px) {
  .exit_popup_holder h2 {
    font-size: 40px;
  }
}