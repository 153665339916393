.pauseScreen {
	position: fixed;
	padding: 0 70px;
	top: 0;
	width: 100%;
	height: 100%;
	background-image:
		linear-gradient(180deg, rgba(0, 0, 0, .85) 4%, rgba(0, 0, 0, 0.55) 60%, rgba(0, 0, 0, 0) 90%),
		linear-gradient(0, rgba(0, 0, 0, 0.9) 7%, rgba(0, 0, 0, 0) 60%);
	z-index: 1;
	color: #fff;
	font-size: 70px;
	opacity: 0;
	pointer-events: none;
	transition: .2s opacity;
	box-sizing: border-box;
	display: none;
}

.pauseScreen-visible {
	opacity: 1;
}

.pauseScreen-wrapper {
	padding-top: 12%;
	opacity: 0;
	transform: translateY(-80px);
	transition: .2s opacity .4s, .4s transform .3s;
}


.pauseScreen-visible .pauseScreen-wrapper {
	opacity: 1;
	transform: translateY(0);
}

.pauseScreen-title {
	font-size: inherit;
	margin: 0;
}

.pauseScreen-description {
	font-size: .4em;
	width: 60%;
	margin: .8em 0;
	-webkit-line-clamp: 6;
}

.pauseScreen-paused {
	position: absolute;
	bottom: 160px;
	font-size: .65em;
	opacity: .7;
	font-weight: 500;
}

@media (max-width: 1280px) and (min-width:1280px) {
	.pauseScreen {
		padding: 0 50px;
		font-size: 52px;
	}

	.pauseScreen-paused {
		bottom: 100px;
	}
}