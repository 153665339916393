.errorContainer {
	position: fixed;
	background-color: rgba(20, 20, 20, .4);
	top: 0;
	z-index: 120;
}

.errorContainer>img {
	position: absolute;
	filter: brightness(.4);
}

.err_msg {
	font-size: 50px;
	text-shadow: 1px 1px 5px #000;
	color: #fff;
	text-align: center;
	font-weight: 500;
	z-index: 1;
}

.err_msg > p{
	line-height: 2em;
}

#chromeImg {
	height: 140px;
}

#castifyImg{
	height: 70px;
	display: block;
	margin: 20px auto 0;
}

@media screen and (max-width: 1280px) {
	.err_msg {
		width: 100%;
		font-size: 35px;
		padding: 0 8px;
		box-sizing: border-box;
	}
	#chromeImg {
		height: 60px;
	}
}